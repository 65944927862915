import React from "react"
import { graphql, useStaticQuery } from "gatsby"
//components
import CustomLink from "../Link/CustomLink"
import Section from "../../Section"

//data
const getData = graphql`
  {
    c: allContentfulService(
      filter: { node_locale: { eq: "nl" } }
      sort: { fields: order, order: ASC }
    ) {
      nodes {
        icon {
          svg {
            content
          }
        }
        slug
        title
        node_locale
      }
    }
    d: contentfulConfig(node_locale: { eq: "nl" }) {
      homeTitleService
    }
  }
`
const HomeService = () => {
  const data = useStaticQuery(getData)
  return (
    <Section classname="homeService" container>
      <h2>{data.d.homeTitleService}</h2>
      <div className="homeServiceContainer">
        {data.c.nodes.map((item, index) => {
          return (
            <div key={index} className="homeServiceElement">
              <CustomLink to={`/${item.node_locale}${item.slug}`}>
                <div
                  className="icon"
                  dangerouslySetInnerHTML={{ __html: item.icon.svg.content }}
                />
                <div className="title">{item.title}</div>
              </CustomLink>
            </div>
          )
        })}
      </div>
    </Section>
  )
}

export default HomeService
