import React from "react"
import Image from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
//css
import "../../../css/banderole.css"
//data
const getData = graphql`
  {
    c: allContentfulBanderole(
      filter: { pageType: { eq: "homepage" }, node_locale: { eq: "fr" } }
    ) {
      nodes {
        logo {
          fixed(height: 100) {
            ...GatsbyContentfulFixed
          }
        }
      }
    }
  }
`
const HomeBanderole = () => {
  const data = useStaticQuery(getData)
  return (
    <div className="banderole front">
      {data.c.nodes.map((item, index) => {
        return <Image key={index} fixed={item.logo.fixed} />
      })}
    </div>
  )
}

export default HomeBanderole
