import React from "react"
import { graphql, useStaticQuery } from "gatsby"
//components
import HomeServiceNl from "../components/Query/Services/HomeServiceNl"
import PresentHomeNl from "../components/Query/Homepage/PresentHomeNl"
import Banderole from "../components/Query/Banderole/HomeBanderole"
import SEO from "../components/SEO"
//css
import "../css/index.css"
//data
const getData = graphql`
  {
    c: allContentfulPage(
      filter: { slug: { eq: "/" }, node_locale: { eq: "nl" } }
    ) {
      nodes {
        contentful_id
        title
        metaDsc {
          metaDsc
        }
      }
    }
  }
`

export default function Home({ pageContext }) {
  const data = useStaticQuery(getData)
  return (
    <>
      {data.c.nodes[0].metaDsc ? (
        <SEO
          title={data.c.nodes[0].title}
          dsc={data.c.nodes[0].metaDsc.metaDsc}
          pageContext={pageContext}
        />
      ) : (
        <SEO title={data.c.title} pageContext={pageContext} />
      )}
      <HomeServiceNl />
      <PresentHomeNl pageContext={pageContext} />
      <Banderole />
    </>
  )
}
